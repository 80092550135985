import { InformationCircleIcon } from '@heroicons/react/24/outline';
import TooltipContent from './TooltipContent';
import { ReactChildren } from './utils';

type Props = {
    children: ReactChildren;
    tooltipTarget?: ReactChildren;
    tooltipSize?: 'sm' | 'base';
    tooltipDirection?: 'top' | 'right' | 'top-left' | 'top-right';
};

export default function Tooltip({ children, tooltipTarget, tooltipSize = 'base', tooltipDirection }: Props) {
    const size = tooltipSize == 'base' ? 'w-8' : 'w-6';

    return (
        <div className="group relative ml-2 w-max">
            {tooltipTarget ? tooltipTarget : <InformationCircleIcon className={`${size} text-cs-gray`} />}
            <TooltipContent className="-top-12" direction={tooltipDirection}>
                {children}
            </TooltipContent>
        </div>
    );
}
